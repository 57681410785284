<template>
  <div>
    <div class="timepicker_wraper">
      <div>
        <div class="">
          <!-- Hour Selection -->
          <div class="text-left gap-2 pt-1 pb-1 flex items-center">
            <div class="w-16"><span>Days:</span></div>
            <div class="ml-1">
              <button :class="dayInputValue <= 0 ? 'timePick_btn_diss' : 'timePick_btn'" :disabled="dayInputValue <= 0"
                      @click="decreaseDays()" small>
                <span>-</span>
              </button>
            </div>
            <input @focus="$event.target.select()" class="dayBox cust_text_field text-center w-12 border border-gray2 focus:border-primary rounded flext items-center justify-center px-1 py-1 " v-model="dayInputValue" v-mask="'##'">
            <div class="ml-1">
              <button :class="'timePick_btn'" @click="increaseDays()" small>+</button>
            </div>
          </div>
          <div class="text-left gap-2 pt-1 pb-1 flex items-center">
            <div class="w-16"><span>Hour:</span></div>
            <div class="ml-1">
              <button :class="hourInputValue <= 0 ? 'timePick_btn_diss' : 'timePick_btn'" :disabled="hourInputValue <= 0"
                      @click="decreaseHours()" small>
                <span>-</span>
              </button>
            </div>
            <input @focus="$event.target.select()" class="minuteBox cust_text_field text-center w-12 border border-gray2 focus:border-primary rounded flext items-center justify-center px-1 py-1 " v-model="hourInputValue" v-mask="'##'">
            <div class="ml-1">
              <button :disabled="hourInputValue >= 23" :class="hourInputValue >= 23 ? 'timePick_btn_diss' : 'timePick_btn'"
                      @click="increaseHours()" small>+
              </button>
            </div>
          </div>
          <!-- Minute Selection -->
          <div class="text-left gap-2 pt-1 pb-1 flex items-center">
            <div class="w-16"><span>Minutes:</span></div>
            <div class="ml-1">
              <button :class="minuteInputValue <= 0 ? 'timePick_btn_diss' : 'timePick_btn'" :disabled="minuteInputValue <= 0"
                      @click="decreaseMin()" small>
                <span>-</span>
              </button>
            </div>
            <input @focus="$event.target.select()" class="minuteBox cust_text_field text-center w-12 border border-gray2 focus:border-primary rounded flext items-center justify-center px-1 py-1 " v-model="minuteInputValue" v-mask="'##'">
            <div class="ml-1">
              <button :disabled="minuteInputValue >= 59" :class="minuteInputValue >= 59 ? 'timePick_btn_diss' : 'timePick_btn'"
                      @click="increaseMin()" small>+
              </button>
            </div>
          </div>
          <!-- Day Selection -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hourInputValue: '0',
      minuteInputValue: '00',
      dayInputValue: '0',
      daysArr: [], // You may initialize this with appropriate day values
      selectedValues: {
        hour: '0',
        minutes: '00',
        days: '1'
      }
    }
  },
  props: ['value', 'title'],
  model: {
    prop: 'value',
    event: 'input'
  },
  mounted () {
    console.log('this.value', this.value)
    if (this.value !== undefined && this.value !== '' && this.value !== null) {
      let x = this.value.split(':')
      this.dayInputValue = parseInt(x[0])
      this.hourInputValue = parseInt(x[1])
      this.minuteInputValue = parseInt(x[2])
    }
  },
  watch: {
    hourInputValue: {
      handler () {
        if (this.hourInputValue >= 0) {
          if (this.hourInputValue === 0) {
            this.hourInputValue = '0'
          }
          if (this.hourInputValue === '') {
            this.hourInputValue = ''
          }
        }
        if (this.hourInputValue > 23) {
          this.hourInputValue = 23
        }
        this.setTime()
      },
      deep: true
    },
    minuteInputValue: {
      handler () {
        if (this.minuteInputValue >= 0) {
          if (this.minuteInputValue === 0) {
            this.minuteInputValue = '0'
          }
          if (this.minuteInputValue === '') {
            this.minuteInputValue = ''
          }
        }
        if (this.minuteInputValue > 59) {
          this.minuteInputValue = 59
        }
        this.setTime()
      },
      deep: true
    },
    dayInputValue: {
      handler () {
        if (this.dayInputValue <= 0) {
          this.dayInputValue = 0
        }
        this.setTime()
      },
      deep: true
    }
  },
  methods: {
    setTime () {
      let hrValue = String(this.hourInputValue)
      let minValue = String(this.minuteInputValue)
      let dayValue = String(this.dayInputValue)
      if (hrValue.length === 1) {
        hrValue = '0' + hrValue
      }
      if (minValue.length === 1) {
        minValue = '0' + minValue
      }
      this.$emit('input', `${dayValue}:${hrValue === '' ? '00' : hrValue}:${minValue}`)
    },
    decreaseHours () {
      this.hourInputValue = Number(this.hourInputValue) - 1
    },
    increaseHours () {
      this.hourInputValue = Number(this.hourInputValue) + 1
    },
    decreaseMin () {
      this.minuteInputValue = Number(this.minuteInputValue) - 1
    },
    increaseMin () {
      this.minuteInputValue = Number(this.minuteInputValue) + 1
    },
    decreaseDays () {
      this.dayInputValue = Number(this.dayInputValue) - 1
    },
    increaseDays () {
      this.dayInputValue = Number(this.dayInputValue) + 1
    }
  }
}
</script>

<style scoped>
.timepicker_wraper {
  font-family: 'Roboto-Regular' !important;
  overflow: hidden;
}
/* Your existing styles */
</style>

<template>
  <div class="timepicker_wraper card rounded-lg p-2">
    <div class="heading-5 font-semibold text-text2">{{ mainTitle }}</div>
    <div class="timepicker_hour_min_picker">
      <p class="timepicker_hour__title">Hours:</p>
      <button v-for="(data, index) in timepickerValues.hoursList" :key="index" class="timepicker_selection__button" :class="checkForHourMatch(data) ? 'timepicker_button__selected' : 'timepicker_button__default'" @click="hrButtonSelectionHandler(data)">
        {{data}}
      </button>
    </div>
    <div class="timepicker_hour_min_picker pt-0">
      <p class="timepicker_hour__title">Minutes:</p>
      <button v-for="(data, index) in timepickerValues.minutesList" :key="index" class="timepicker_selection__button" :class="data === selectedValues.minutes ? 'timepicker_button__selected' : 'timepicker_button__default'" @click="minButtonSelectionHandler(data)">
        {{data}}
      </button>
      <p class="timepicker_customer_date__header">Custom time? type here</p>
      <div style="display:flex">
        <div class="timepicker_input">
          <input @input="checkForHourInput" class="timepicker_input__hr_min" style="text-align:right" type="number" @blur="checkForValidHourValue" v-model="hourInputValue" />
          <p style="padding: 0px 5px">:</p>
          <input @input="addZeroInMinutes" class="timepicker_input__hr_min" style="text-align:left" type="number" v-model="minuteInputValue" @blur="minuteInputValue = minuteInputValue === '' ? '00' : minuteInputValue" />
        </div>
        <div class="timepicker_am_pm__wrapper">
          <button v-for="(data, index) in timepickerValues.selectionList" :key="index" class="timepicker_selection__button" :class="data === hrSelectedValue ? 'timepicker_button__selected' : 'timepicker_button__default'" @click="hrSelectedValue = data;toggleBetweenTimePeriod()">
          {{data}}
        </button>
        </div>
      </div>
      <div class="flex justify-end pt-2" v-if="isCustomPicker === false">
        <Button :btnSize="'small'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="save(false)"/>
        <Button class="ml-2" :btnSize="'small'" :textColor="'white'" :btnColor="'primary'" :btnText="`Save`" @buttonAction="save(true)"/>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
export default {
  components: {
    Button
  },
  data () {
    return {
      sendVal: '',
      timepickerValues: {
        hoursList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        minutesList: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
        selectionList: ['AM', 'PM']
      },
      hourInputValue: '12',
      minuteInputValue: '00',
      // AM or PM
      hrSelectedValue: 'AM',
      selectedValues: {
        hour: '12',
        minutes: '00'
      }
    }
  },
  props: ['value', 'isCustomPicker', 'showDiscard', 'mainTitle', 'time'],
  mounted () {
    console.log('isCustomPickerisCustomPickerisCustomPickerisCustomPicker', this.value)
    if (this.value !== undefined && this.value !== '' && this.value !== null) {
      let x = this.value.split(':')
      this.hourInputValue = parseInt(x[0]) > 12 ? parseInt(x[0]) - 12 : parseInt(x[0]).toString()
      this.minuteInputValue = x[1]
      this.selectedValues.hour = this.hourInputValue
      this.selectedValues.minutes = x[1]
      this.hrSelectedValue = parseInt(x[0]) > 12 ? 'PM' : 'AM'
    }
    this.selectedDate()
  },
  methods: {
    save(from) {
      console.log('save', from)
      console.log('------------', )
      console.log('minuteInputValue', this.minuteInputValue)
      console.log('hourInputValue', this.hourInputValue)
      this.$root.$emit('closeDialogFromTime', from, this.sendVal)
    },
    addZeroInMinutes () {
      if (this.minuteInputValue.length > 2) {
        this.minuteInputValue = this.minuteInputValue.substring(this.minuteInputValue.length - 2, this.minuteInputValue.length)
      }
      if (parseInt(this.minuteInputValue) < 60) {
        this.selectedValues.minutes = parseInt(this.minuteInputValue) < 10 ? '0' + parseInt(this.minuteInputValue).toString() : this.minuteInputValue
      } else {
        this.minuteInputValue = '55'
      }
      this.selectedDate()
    },
    checkForHourMatch (data) {
      return parseInt(this.selectedValues.hour).toString() === data
    },
    hrButtonSelectionHandler (data) {
      this.selectedValues.hour = data
      this.hourInputValue = data
      this.selectedDate()
    },
    minButtonSelectionHandler (data) {
      this.selectedValues.minutes = data
      this.minuteInputValue = data
      this.selectedDate()
    },
    async changeTimeAsPerSelectedOption () {
      if (parseInt(this.hourInputValue) > 12) {
        this.hourInputValue = (parseInt(this.hourInputValue) - 12).toString()
      }
    },
    // AM or PM button click handler
    async toggleBetweenTimePeriod () {
      await this.changeTimeAsPerSelectedOption()
      this.selectedDate()
    },
    checkForValidHourValue () {
      if (parseInt(this.hourInputValue) > 12 || this.hourInputValue === '') {
        this.hourInputValue = '11'
        this.selectedValues.hour = '11'
        this.hrSelectedValue = 'PM'
      }
      this.selectedDate()
    },
    checkForHourInput (data) {
        console.log(data)
      if (this.hourInputValue.length > 2) {
        this.hourInputValue = this.hourInputValue.substring(this.hourInputValue.length - 2, this.hourInputValue.length)
      }
      if (parseInt(this.hourInputValue) < 24) {
        this.selectedValues.hour = parseInt(this.hourInputValue) > 12 ? (parseInt(this.hourInputValue) - 12).toString() : this.hourInputValue
        if (parseInt(this.hourInputValue) > 12) {
          this.hourInputValue = (parseInt(this.hourInputValue) - 12).toString()
          this.hrSelectedValue = 'PM'
        } else {
          this.hrSelectedValue = 'AM'
        }
      }
      this.selectedDate()
    },
    // data = AM or PM
    async selectedDate (data) {
      let hrValue = await this.getHourValue(data)
      if (hrValue === 'NaN') return
      if (parseInt(hrValue) < 24 && parseInt(this.selectedValues.minutes) < 60) {
        this.sendVal = `${hrValue === '' ? '00' : hrValue}:${this.selectedValues.minutes}:00`
        // this.$emit('input', )
      }
    },
    async getHourValue (data) {
      if (data === undefined) {
        let hrValue = this.hrSelectedValue === 'AM' ? this.selectedValues.hour : (parseInt(this.selectedValues.hour) + 12).toString()
        return parseInt(hrValue) % 12 === 0 ? this.hrSelectedValue === 'PM' ? '12' : '00' : parseInt(hrValue) < 10 ? '0' + hrValue : hrValue
      } else {
        let hrValue = data.srcElement.value === 'AM' ? this.selectedValues.hour : (parseInt(this.selectedValues.hour) + 12).toString()
        return parseInt(hrValue) % 12 === 0 ? this.hrSelectedValue === 'PM' ? '12' : '00' : parseInt(hrValue) < 10 ? '0' + hrValue : hrValue
      }
    }
  }
}
</script>
<style scoped>
.timepicker_wraper {
  font-family: 'Roboto-Regular' !important;
  max-width: 310px !important;
  width: 310px !important;
  overflow: hidden;
  height: 410px !important;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(92, 91, 91, 0.2);
}
.timepicker_input{
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 5px 0px;
}
.timepicker_input__hr_min {
  width: 60px !important;
  height: 35px;
  border: solid 2px gray;
  font-size: 18px;
  outline: none;
  padding: 0px 10px;
  outline: none;
  border-radius: 3px;
}
.timepicker_input__hr_min:focus {
  border: solid 2px #1295BA;
}
.timepicker_hour__title {
  text-align: left;
  padding-left: 5px;
}
.timepicker_hour_min_picker{
  padding: 10px;
}
.timepicker_selection__button{
  margin: 3px;
  width: 30x;
  width: 39px;
  outline: none;
  font-family: 'Roboto-Regular';
  font-size: 15px;
  height: 35px;
  border-radius: 3px;
}
.timepicker_button__default {
  border: solid 1px gray;
  color: gray;
  background: white;
}
.timepicker_button__default:hover{
  background-color: #E3F2FD !important;
  color: black;
}
.timepicker_button__selected {
  border: solid 1px #1295BA;
  color: white;
  background: #1295BA;
}
.timepicker_button__discard {
  border: solid 1px #7c7c7c;
  color: rgb(255, 255, 255);
  background: #979797;
}
.timepicker_input__selection {
  height: 35px;
  width: 50px;
  padding-left: 10px;
  font-size: 16px;
  border: solid 2px gray;
  border-radius: 3px;
  background: white;
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.timepicker_am_pm__wrapper {
  width: 148px !important;
  text-align: end;
}
.timepicker_customer_date__header {
  font-family: 'Roboto';
  font-size: small;
  padding: 8px 0px 0px;
}
</style>
